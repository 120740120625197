<template>
  <base-layout  >
    <ion-searchbar
                   show-cancel-button="focus"
                   @ionChange="filter(search)"
                   :value="search"
                   @ionInput="search = $event.target.value;" placeholder="Search"
    ></ion-searchbar>
    <ion-content class="has-header has-subheader "   >

      <div >

        <div class="wcpt-list"  >
          <div v-for="sponsor in filteredSponsors" :key="sponsor.id">
            <div
                class="web-person-divider"
                v-if="!sponsor.id"
                style="flex: 0 0 100vw;width: 100vw;padding-left:20px"
            >
              <h2>{{ sponsor }}</h2>
            </div>

            <div v-if="sponsor.id">
              <a
                  @click="showDisclaimer(sponsor)"
                  class="wcpt-list-item item item-icon-right"
                  style="text-align: center; height:130px;"
              >
                <img
                    v-if="sponsor.logo"
                    :src="'https://fileserver-188ab.kxcdn.com/file/' +sponsor.logo.split('/')[4]"
                    style="max-height: 70px; padding:10px 20px 0 20px"
                />
                <p>{{ sponsor.short_name || sponsor.name }}<br>
                  <small v-for="location in sponsor.locationList" :key="location.id">{{ location }}</small></p>

              </a>
            </div>
          </div>
        </div>
      </div>

    </ion-content>
  </base-layout>
</template>
<script>
import {IonContent, IonSearchbar, alertController} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapState} from "vuex";

export default defineComponent({
  name: "Exhibitors",
  data() {
    return {
      sponsors: [],
      search: "",
      filtered: []
    };
  },
  components: {
    IonContent,
    IonSearchbar
  },
  methods: {
    ...mapActions("exhibitors", ["getSponsorList"]),
    ...mapActions('googleanalytics', ['trackWithLabel']),

    filter(term) {
      if (term.length > 0) {
        this.filtered = this.sponsors.filter(sponsor =>
            Object.keys(sponsor).some(k =>
                sponsor[k]
                    ? sponsor[k].toString().toLowerCase().includes(term.toLowerCase())
                    : ""
            )
        );
      } else {
        this.filtered = [];
      }
    },

    async showDisclaimer (sponsor) {
      if(sponsor.disclaimer && sponsor.disclaimer.length > 10){
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: sponsor.name,
              message: '<strong>' + sponsor.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(sponsor.name);
                    if (sponsor.disclaimer_link) {
                      window.open(sponsor.disclaimer_link, '_blank', 'location=no');
                    } else {
                      this.$router.push('/app/exhibitors/' + sponsor.id);
                    }
                  },
                },
              ],
            });
        return alert.present();
      }else if(sponsor.disclaimer_link && sponsor.disclaimer_link.length > 10){
        window.open(sponsor.disclaimer_link, '_blank', 'location=no');
      }else{
        this.$router.push('/app/exhibitors/' + sponsor.id);
      }

    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    filteredSponsors: function () {
      return this.filtered.length ? this.filtered : this.sponsors;
    }
  },
  async created() {
    this.sponsors = await this.getSponsorList();
  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        this.sponsors = await this.getSponsorList();
      }
    },
  },
});
</script>
<style lang="scss">
a {
  text-decoration: none;
}
</style>
